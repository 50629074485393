import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  searchMoreTags,
  toggleShowFilters,
} from 'services/state/actions/searchActions';
import { clearSearch, changeOrder } from 'services/state/actions/searchActions';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const useSearchPage = () => {
  const searchTagsState = useSelector((state) => state.searchTags);
  const tagsState = useSelector((state) => state.tags);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { included, excluded, keywords, similarTitle, similarArtist, term } =
    tagsState;
  const { count, tracks, trackNext } = searchTagsState;
  const { ordering, loading, error } = searchTagsState;

  // Create an object with 'title' and 'artist' properties only if both exist
  const similar = similarTitle &&
    similarArtist && { title: similarTitle, artist: similarArtist };

  useEffect(() => {
    // Clear the search if navigating away from the search page
    return () => {
      if (!location.pathname.includes('search')) {
        dispatch(clearSearch());
      }
    };
  }, [dispatch, location.pathname]);

  // Apply a tag to the search query
  const applyTag = (tag, include) => {
    // Create a new URLSearchParams object with the current search parameters
    const query = new URLSearchParams(location.search);

    // Filter tags based on whether to include or exclude the given tag
    const filterTags = (tags, include) => {
      let t = tags.map((t) => t.id);

      // If include (vs exclude) is false, remove the tag from the list
      if (!include) {
        return t.filter((t) => t !== tag.id);
      }
      // If include (vs exclude) is true, add the tag to the list if it's not already present,
      // otherwise, remove it from the list
      return t.some((t) => t === tag.id)
        ? t.filter((t) => t !== tag.id)
        : [...t, tag.id];
    };

    // Filter the included and excluded tags based on the given include parameter
    const inc = filterTags(included, include);
    const exc = filterTags(excluded, !include);

    // Update the 'inc' and 'exc' parameters in the query

    inc?.length ? query.set('inc', inc.join(',')) : query.delete('inc');
    exc?.length ? query.set('exc', exc.join(',')) : query.delete('exc');

    query.delete('keywords');

    // Push the updated query to the history, updating the URL and triggering a search
    navigate({
      pathname: '/search',
      search: query.toString(),
    });
  };

  // Clear all tags from the search query
  const clearTags = () => {
    // Create a new URLSearchParams object with the current search parameters
    const query = new URLSearchParams(location.search);

    // Loop through the list of parameters and delete them from the query
    ['inc', 'exc', 'slb', 'ssp', 't', 'a'].forEach((t) => query.delete(t));

    // Push the updated query to the history, updating the URL and triggering a search
    navigate({
      pathname: location.pathname,
      search: query.toString(),
    });
  };

  // Handle applying a tag with the given include/exclude flag
  const handleTag = (tag, include) => {
    // Call the applyTag function with the specified tag and include flag
    applyTag(tag, include);
  };

  // Handle toggling the display of filters
  const handleFilters = () => {
    // Call the toggleShowFilters action through dispatch
    dispatch(toggleShowFilters());
  };

  // Handle changing the sort order
  const handleSort = (order) => {
    // Call the changeOrder action with the specified order and tag lists
    dispatch(changeOrder({ included, excluded, order }));
  };

  // Handle loading more tags for the search
  const handleMore = () => {
    // Call the searchMoreTags action with the current included and excluded tag lists
    dispatch(searchMoreTags({ included, excluded }));
  };

  return {
    count,
    tracks,
    trackNext,
    ordering,
    loading,
    included: included ?? [],
    excluded: excluded ?? [],
    handleTag,
    handleFilters,
    handleSort,
    handleMore,
    error,
    applyTag,
    similar,
    clearTags,
    keywords: keywords?.length ? keywords : null,
    term,
  };
};

export default useSearchPage;
